import React, { useState, useEffect } from 'react';
import { Paper, Box, InputAdornment, Typography, Divider, Grid, TextField } from '@mui/material';
import { Button, ActionButton, TextInput } from "../../common/components/common/inputs";

import useAxiosPrivate from "../../common/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../common/hooks/useAppContext';
import { RewardSettingService } from "../../services/RewardSettingService";
import { Notification } from '../../common/components/common';
import notifyError from '../../common/errors/notifyError';
import { disabledRoles } from '../../common/data';
import { useStyles } from './styles';
import { useBaseStyles } from '../../common/styles';
import { RadioGroup } from '../../common/components/common/inputs';


const delayItems = [
    { id: 1, value: 7, label: '7 days' },
    { id: 2, value: 14, label: '14 days' },
    { id: 3, value: 30, label: '30 days' },
]

const expiringItems = [
    { id: 1, value: 1, label: '1 year' },
    { id: 2, value: 2, label: '2 years' },
    { id: 3, value: 3, label: '3 years' },
    { id: 4, value: 0, label: 'Never expired' },
]

//all 
// loyalty name
//to do : custom, set once, never change
// reminder: never change, if need to change, 
// free: delete app after install
// paid: contact to change
//merchant: store and user agreement, check out: opt in

//general:confirm


export default function General() {
    const classes = useStyles();
    const baseClasses = useBaseStyles();

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });

    const [postPointsDelay_days, setPostPointDelay_days] = useState(30);
    const [isToNotifyPending, setIsToNotifyPending] = useState();
    const [isToNotifyPosted, setIsToNotifyPosted] = useState();
    const [pointExpiring_years, setPointExpiring_years] = useState(2);
    const [redeemValuePerPoint, setRedeemValuePerPoint] = useState(0.01);


    const handleDelayChange = e => {
        e.preventDefault();
        setPostPointDelay_days(e.target.value);
        RewardSettingService.updateDelay(axiosPrivate, e.target.value);
    }

    const handleExpiringChange = e => {
        e.preventDefault();
        setPointExpiring_years(e.target.value);
        RewardSettingService.updateExpiring(axiosPrivate, e.target.value);
    }

    const handleRedeemValueChange = e => {
        e.preventDefault();
        setRedeemValuePerPoint(e.target.value);
        RewardSettingService.updateRedeemValue(axiosPrivate, e.target.value);
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        RewardSettingService.getConfiguration(axiosPrivate)
            .then(response => {
                // const config = response.data.config;
                // setPostPointDelay_days(config.postPointsDelay_days);
                // setNotifyPending(config.isNotifePending);
                // setNotifyPosting(config.isToNotifyPosted);
                // setPointExpiring_years(config.pointExpiring_years);
                // setRedeemValuePerPoint(config.redeemValuePerPoint);
                // setRedeemThreshhold(config.redeemThreshhold);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, auth]);


    return (
        <Box sx={{ margin: 2 }}>
            <Grid container alignItems="stretch" justify='space-around' spacing={2}>
                <Grid item xs={6} lg={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Point Posting
                        </Typography>
                        <Divider />
                        <RadioGroup
                            label="Posted after"
                            name="delay"
                            value={postPointsDelay_days}
                            onChange={handleDelayChange}
                            items={delayItems}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Email
                        </Typography>
                        <Divider />

                    </Paper>
                </Grid>

                <Grid item xs={12} lg={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Point Expiring
                        </Typography>
                        <Divider />
                        <RadioGroup
                            label="Point Expiring"
                            name="pointExpiring_years"
                            value={pointExpiring_years}
                            onChange={handleExpiringChange}
                            items={expiringItems}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Redeem
                        </Typography>
                        <Divider />
                        <TextField
                            type='number'
                            label="Redeem Value Per point"
                            variant="outlined"
                            size="small"
                            name="redeemValue"
                            value={redeemValuePerPoint}
                            onChange={handleRedeemValueChange}
                            sx={{
                                width: '60%'
                            }}
                        />

                    </Paper>
                </Grid>

            </Grid>

            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </Box>
    )
}