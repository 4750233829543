import React, { useEffect } from 'react';
import { Typography, Grid, Box, Divider } from '@mui/material';
import { useBaseStyles } from '../../common/styles';
import useAppContext from '../../common/hooks/useAppContext';

function CustomerMenu({ customers, prevSelCutomer, selectedCustomer, updateSelectedOrder }) {
    const baseClasses = useBaseStyles();
    const { store } = useAppContext();
    const abbrevStoreName = store?.abbrevStoreName;

    useEffect(() => {
        if (prevSelCutomer) // may not exist, because storeId or search condition changes in admin.spotfraud.app
            document.querySelector(`#customer_${prevSelCutomer._id}`)?.classList.remove('selected');
        if (selectedCustomer)
            document.querySelector(`#customer_${selectedCustomer._id}`).classList.add('selected');
    }, [selectedCustomer]);


    const handleClick = (e, newCustomer) => {
        e.preventDefault();
        if (selectedCustomer.id !== newCustomer.id) {
            updateSelectedOrder(newCustomer);
        }
    }
    return (
        <Box sx={{
            backgroundColor: 'white',
        }}>
            <Divider sx={{ mt: 2 }} />
            {customers.length === 0 ?
                <Typography>No Customers</Typography> :
                customers.map((customer, index) => (
                    <div key={index} >
                        <Grid container key={index} className='item' id={`customer_${customer._id}`}
                            sx={{ pb: 1 }}
                            onClick={(e) => handleClick(e, customer)}
                        >
                            <Grid item key={1} xs={4} className={baseClasses.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    {customer.abbrevStoreName || abbrevStoreName}
                                </Typography>
                            </Grid>

                            <Grid item key={2} xs={4} className={baseClasses.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    {customer.currency_symbol}{customer.total}
                                </Typography>
                            </Grid>

                            <Grid item key={3} xs={4} className={baseClasses.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    {customer.date}
                                </Typography>
                            </Grid>

                            <Grid item key={4} xs={5}>
                                <Typography sx={{ width: '100%', fontSize: 13 }}>
                                    {customer.email}
                                </Typography>
                                <Typography sx={{ width: '100%', fontSize: 11 }}>
                                    {customer.first_name} {customer.last_name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                    </div>
                ))
            }
        </Box >
    );
}

export default React.memo(CustomerMenu);

