import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(3),
        padding: theme.spacing(2),
        backgroundColor: 'white',
        overflowX: "scroll"
    },
    paper: {
        textAlign: 'left',
        padding: theme.spacing(2),
        height: 260
    }
}))