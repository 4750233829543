import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledTypography = styled(Typography)(({ theme }) => ({
    //the textTransform and fontSize properties only change via sx setting, instead of classes
    textTransform: 'capitalize',
    fontSize: 13
}));

export {
    StyledTypography
}