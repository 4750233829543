import React, { useReducer } from 'react';
import { useForm, Form } from '../../common/components/common/useForm';
import { Button } from '../../common/components/common/inputs';
import { TextField, Box, MenuItem, Typography } from '@mui/material';

import { reducer } from '../../common/components/common/reducer';
import { defaultState, closeModal } from './RuleUtil';
import Modal from '../../common/components/common/Modal';

const initAddr = {
    _id: 0,
    name: '',
    spendingThreshhold: '',
    pointsPerSpending: ''
}

export default function TierForm(props) {
    const { addOrEdit, recordForEdit } = props;
    const resetAddr = { ...initAddr };
    if (recordForEdit) {
        resetAddr._id = recordForEdit._id;
    }
    const [state, dispatch] = useReducer(reducer, defaultState);

    const {
        values,
        setValues,
        handleInputChange,
        resetForm
    } = useForm(recordForEdit || initAddr, resetAddr, false);


    //*****NOTE*****
    //To respond to reset button,  useEffect with values dependency is slow,
    // Moreover, it works only when adding following code to the handleCountryChange function
    // setValues({ ...values, country });
    // (change the country part of the values to trigger values to be stored in a different address,
    //so when clicking on reset button to setValues, the above useEffect condition can be fired surely)
    //The reason is it will be called whenever the storage address of values changes, due to monitored in the event loop


    const handleReset = () => {
        resetForm();
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (values.name && values.spendingThreshhold && values.pointsPerSpending) {
            addOrEdit({ ...values }, resetForm);
        } else {
            dispatch({ type: 'NO_VALUE' });
        }
    };

    return (
        <>
            {state.isModalOpen && <Modal closeModal={() => closeModal(dispatch)} modalContent={state.modalContent} />}

            <Form onSubmit={handleSubmit}>
                <Box sx={{
                    mx: 'auto',
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 400,
                    width: '100%',
                    overflow: "hidden"
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>
                    <TextField
                        type='text'
                        label="Name"
                        variant="outlined"
                        size="small"
                        name="name"
                        value={values.name || ''}
                        placeholder="Silver"
                        onChange={handleInputChange}
                        sx={{
                            width: '100%'
                        }}
                    />
                    <TextField
                        type='number'
                        label="Minimum Spending to reach this level"
                        variant="outlined"
                        size="small"
                        name="spendingThreshhold"
                        value={values.spendingThreshhold || ''}
                        placeholder="500"
                        onChange={handleInputChange}
                        sx={{
                            width: '100%'
                        }}
                    />

                    <TextField
                        type='number'
                        label="Points Earned per Spending"
                        variant="outlined"
                        size="small"
                        name="pointsPerSpending"
                        value={values.pointsPerSpending || ''}
                        onChange={handleInputChange}
                        sx={{
                            width: '100%'
                        }}
                        required
                    />
                </Box>
            
                <Button
                    type="submit"
                    text="Submit" />
                <Button
                    text="Reset"
                    color="grey"
                    onClick={handleReset} />
            </Form>
        </>
    )
}


