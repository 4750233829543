export const RewardSettingService = {
    getConfiguration(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/reward_settings`);
    },

    updateDelay(axiosPrivate, postPointsDelay_days) {
        return axiosPrivate.patch(`/api/v1/reward_settings/post_point_delay`, { postPointsDelay_days });
    },

    updateEmailNotification(axiosPrivate, notification) {
        return axiosPrivate.patch(`/api/v1/reward_settings/email_notification`, { notification });
    },

    updateExpiring(axiosPrivate, pointExpiring_years) {
        return axiosPrivate.patch(`/api/v1/reward_settings/point_expiring`, { pointExpiring_years });
    },

    updateRedeemValue(axiosPrivate, redeemValuePerPoint) {
        return axiosPrivate.patch(`/api/v1/reward_settings/redeem_value`, { redeemValuePerPoint });
    }
}

//java static class