import React, { useState, useEffect } from 'react';
import { Paper, TableBody, TableRow, TableCell, Box, InputAdornment, TextField, Button, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Search from '@mui/icons-material/Search';
import useAxiosPrivate from "../../common/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../common/hooks/useAppContext';
import { TextInput } from "../../common/components/common/inputs";
import { useTable, PageHeader, Notification, ConfirmDialog } from '../../common/components/common';
import notifyError from '../../common/errors/notifyError';
import { useBaseStyles } from '../../common/styles';
import { disabledRoles } from '../../common/data';

const headCells = [
    { id: 'date', label: 'Date' },
    { id: 'ordeNo', label: 'Order No' },
    { id: 'points', label: 'Points' },
]

const items = [
    {
        date: "Nov 7 2024",
        orderNo: "109",
        points: 25
    },
    {
        date: "Nov 7 2024",
        orderNo: "108",
        points: 25
    },
    {
        date: "Nov 7 2024",
        orderNo: "107",
        points: 25
    }
]

export default function RewardHistory() {
    const baseClasses = useBaseStyles();
    const [email, setEmail] = useState('');
    const [records, setRecords] = useState(items);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        // RedFlagService.getEmails(axiosPrivate)
        //     .then(response => {
        //         isMounted && setRecords(response.data);
        //     })
        //     .catch(err => {
        //         notifyError(err, setNotify, navigate, location);
        //     });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, auth]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells, filterFn);

    return (
        <>
            <PageHeader
                title="Activity"
            />

            <Paper className={baseClasses.pageContent}>
                <Box sx={{
                    minWidth: 500,
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map((item, index) =>
                                (<TableRow key={index}>
                                    <TableCell>{item.date}</TableCell>
                                    <TableCell>{item.orderNo}</TableCell>
                                    <TableCell>{item.points}</TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </Box>
            </Paper>
        </>
    )
}